import moment from "moment-timezone";
import { ClimateMetric, SoilMetric } from "../constants/metrics";
import api, { ServerResponse } from "./agritech";

export type RepeatConfig =
  | "always"
  | "10min"
  | "15min"
  | "20min"
  | "30min"
  | "45min"
  | "hourly"
  | "90min"
  | "2hr"
  | "never";

export type SMSAction = {
  type: "sms";
  phone_number: string;
  message: string;
  repeat?: RepeatConfig;
};

export type EmailAction = {
  type: "email";
  email: string;
  message: string;
};
export type IrrigatorAction = {
  type: "irrigator";
  irrigator_id: number;
  command_id: number;
  command: string;
  repeat?: RepeatConfig;
};

export type TalgilAction = {
  type: "talgil";
  irrigator_id: number;
  program_id: number;
};

export type Action = SMSAction | TalgilAction | EmailAction | IrrigatorAction;

export type MetricCondition<M extends string> = {
  metric: M;
  threshold: number;
  comparison: "gt" | "lt";
};

export type ClimateCondition = MetricCondition<ClimateMetric> & {
  type: "climate";
};

export type SoilCondition = MetricCondition<SoilMetric> & { type: "soil" };

export type SoilClimateCondition = {
  type: "soil_climate";
  threshold_id?: number; // integer threshold id rather than a number
  threshold_manual?: number;
  threshold_name: string;
  comparison: "gt" | "lt";
};

export type TimeCondition = {
  type: "time";
  start: string;
  end: string;
};

type OrCondition = {
  type: "or";
  conditions: Condition[];
};

type AndCondition = {
  type: "and";
  conditions: Condition[];
};

export type SingleCondition =
  | ClimateCondition
  | SoilCondition
  | SoilClimateCondition
  | TimeCondition;

export type Condition = SingleCondition | OrCondition | AndCondition;

export type Automation = {
  id: number;
  field_id: number;
  field_name?: string;
  name: string;
  invalid: boolean;
  last_triggered?: string;
  data: {
    disabled?: boolean;
    actions: Action[];
    conditions: Condition[];
  };
};

export type AutomationEvent = {
  id: number;
  automation_id: number;
  description: string;
  timestamp: string;
};

type Modify<T, R> = Omit<T, keyof R> & R;
type ServerAutomation = Modify<Automation, { data: string }>;

export async function listAutomations(
  token: string,
  client_id: number
): Promise<ServerResponse<Automation[]>> {
  const response = await api.get<ServerResponse<ServerAutomation[]>>(
    `${client_id}/automations`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return {
    ...response.data,
    data: response.data.data.map((automation) => ({
      ...automation,
      data: JSON.parse(automation.data),
    })),
  };
}

export async function getAutomation(
  token: string,
  client_id: number,
  automation_id: number
): Promise<ServerResponse<Automation>> {
  const response = await api.get<ServerResponse<ServerAutomation>>(
    `${client_id}/automations/${automation_id}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return {
    ...response.data,
    data: {
      ...response.data.data,
      data: JSON.parse(response.data.data.data) as Automation["data"],
    },
  };
}

export async function addAutomation(
  token: string,
  client_id: number,
  automation: Omit<Automation, "id">
) {
  const response = await api.post<ServerResponse<Automation>>(
    `${client_id}/automations`,
    automation,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function updateAutomation(
  token: string,
  client_id: number,
  automation: Automation
) {
  const response = await api.patch<ServerResponse<Automation>>(
    `${client_id}/automations/${automation.id}`,
    automation,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function deleteAutomation(
  token: string,
  client_id: number,
  automation_id: number
) {
  const response = await api.delete<ServerResponse<Automation>>(
    `${client_id}/automations/${automation_id}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function listAutomationEvents(
  token: string,
  client_id: number,
  automation_id: number
): Promise<ServerResponse<AutomationEvent[]>> {
  const response = await api.get<
    ServerResponse<AutomationEvent[] | { [idx: string]: AutomationEvent }>
  >(`${client_id}/automations/${automation_id}/events`, {
    headers: { Authorization: "Bearer " + token },
  });

  let events: AutomationEvent[];
  if (Array.isArray(response.data.data)) {
    events = response.data.data;
  } else {
    events = Object.values(response.data.data);
  }
  events.sort((a, b) => moment(b.timestamp).diff(moment(a.timestamp)));
  // convert timestamps from UTC to local time
  events = events.map((event) => ({
    ...event,
    timestamp: moment
      .utc(event.timestamp)
      .local()
      .format("YYYY-MM-DD HH:mm:ss"),
  }));
  return {
    ...response.data,
    data: events,
  };
}
