import React, { useMemo, useEffect } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { Typography, Divider, Grid, IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import {
  TextInput,
  SelectInput,
  CheckboxInput,
  ToggleInput,
  AutocompleteSelectInput,
} from "../FormInputs";
import { useStyles } from "../FormSelection";
import { objToSelectOptions, forceInt, listTimeZones } from "../../../helpers";

const validate = (values) => {
  const errors = {};
  const requiredInput = [
    "name",
    "client_id",
    "farm",
    "field_type",
    "crop_type",
  ];
  requiredInput.forEach((input) => {
    if (!values[input]) {
      errors[input] = "Required";
    }
  });

  if (values.sensors && values.sensors.length) {
    const sensorsArrayErrors = [];
    values.sensors.forEach((sensor, index) => {
      const sensorErrors = {};
      const requiredSensorInput = ["metric_id", "logger_id", "ref"];
      requiredSensorInput.forEach((input) => {
        if (!sensor || !sensor[input]) {
          sensorErrors[input] = "Required";
          sensorsArrayErrors[index] = sensorErrors;
        }
      });
    });
    if (sensorsArrayErrors.length) {
      errors.sensors = sensorsArrayErrors;
    }
  }
  return errors;
};

const RenderSensors = ({
  fields,
  meta: { error, submitFailed },
  loggers,
  metrics,
}) => {
  const loggerOptions = useMemo(
    () => objToSelectOptions(loggers, "id", "logger_id"),
    [loggers]
  );

  const metricsOptions = useMemo(
    () => objToSelectOptions(metrics, "id", "name"),
    [metrics]
  );
  const metricsWithConfig =
    metrics &&
    metrics.reduce((arr, item) => {
      if (item.ref === "run_off_in") {
        arr.push(item.id);
      }
      return arr;
    }, []);

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Typography variant="h5">Sensors</Typography>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="Add"
            size="small"
            type="button"
            title="Add"
            color="primary"
            onClick={() => fields.push({})}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
      {submitFailed && error && <span>{error}</span>}
      {fields.map((sensor, index, ...rest) => {
        const { metric_id } = rest[0].get(index);
        return (
          <Grid key={index} container alignItems="center">
            <Grid item xs>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md>
                      <Field
                        name={`${sensor}.metric_id`}
                        id={`${sensor}.metric_id`}
                        component={SelectInput}
                        label="Metric"
                        options={metricsOptions}
                        normalize={forceInt}
                      />
                    </Grid>
                    <Grid item md>
                      <Field
                        name={`${sensor}.logger_id`}
                        id={`${sensor}.logger_id`}
                        component={AutocompleteSelectInput}
                        label="Logger ID"
                        options={loggerOptions}
                        normalize={forceInt}
                      />
                    </Grid>
                    <Grid item md>
                      <Field
                        name={`${sensor}.ref`}
                        id={`${sensor}.ref`}
                        component={TextInput}
                        type="text"
                        label="API Node Reference"
                      />
                    </Grid>
                    {metricsWithConfig.length &&
                      metricsWithConfig.includes(metric_id) && (
                        <Grid item md>
                          <Field
                            name={`${sensor}.multiplier`}
                            id={`${sensor}.multiplier`}
                            component={TextInput}
                            type="number"
                            min={1}
                            label="Multiplier"
                          />
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}></Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Remove"
                size="small"
                type="button"
                title="Remove"
                onClick={() => fields.remove(index)}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

const FieldForm = ({
  handleSubmit,
  error,
  valid,
  pristine,
  submitting,
  isValid,
  isWaiting,
  clients,
  initialValues,
  loggers,
  metrics,
}) => {
  useEffect(() => {
    isValid(!pristine && valid && !submitting);
    isWaiting(submitting);
  });
  const classes = useStyles();

  const frequencyOptions = {
    5: "5 minutes",
    15: "15 minutes",
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Field
        name="import"
        id="import"
        component={CheckboxInput}
        label="Import External API data"
      />
      <Field
        name="import_frequency"
        id="import_frequency"
        component={SelectInput}
        label="Import Frequency"
        options={frequencyOptions}
      />
      <Field
        component={TextInput}
        name="name"
        id="name"
        autoFocus={!initialValues}
        label="Name"
      />
      <Field
        component={SelectInput}
        name="client_id"
        id="client_id"
        label="Client"
        disabled={initialValues ? true : false}
        options={clients}
      />
      <Field component={TextInput} name="farm" id="farm" label="Farm" />
      <Field
        component={SelectInput}
        name="field_type"
        id="field_type"
        label="Field Type"
        options={{ substrate: "Substrate", soil: "Soil" }}
      />
      <Field
        component={TextInput}
        name="crop_type"
        id="crop_type"
        label="Crop Type"
      />
      <Field
        component={SelectInput}
        name="timezone"
        id="timezone"
        label="Timezone"
        options={listTimeZones()}
      />
      <Field
        label="Automatic Drain Facility"
        name="auto_drain_facility"
        id="auto_drain_facility"
        component={ToggleInput}
      />
      <Field
        component={SelectInput}
        name="conductivity_line"
        id="conductivity_line"
        label="Conductivity Line"
        options={{
          default: "Default",
          10: "10 cm",
          20: "20 cm",
          30: "30 cm",
          40: "40 cm",
          50: "50 cm",
          60: "60 cm",
          70: "70 cm",
          80: "80 cm",
          90: "90 cm",
        }}
      />
      <Field
        component={TextInput}
        type="number"
        name="runoff_multiplication_factor"
        id="runoff_multiplication_factor"
        label="Run-off multiplication factor (used for app)"
      />
      <Field
        component={TextInput}
        type="number"
        name="chart_runoff_multiplication_factor"
        id="chart_runoff_multiplication_factor"
        label="Chart run-off multiplication factor"
      />
      <Field
        component={SelectInput}
        name="ec_division_factor"
        id="ec_division_factor"
        label="Ec Division Factor"
        valueType="number"
        options={{
          1: "1",
          10: "10",
          100: "100",
          1000: "1000",
          10000: "10000",
        }}
      />

      <Typography variant="body1" gutterBottom color="error">
        <b>{error}</b>
      </Typography>

      <Divider className={classes.divider} />

      <FieldArray
        name="sensors"
        component={RenderSensors}
        metrics={metrics}
        loggers={loggers}
      />
    </form>
  );
};

export default reduxForm({ form: "FieldForm", validate })(FieldForm);
