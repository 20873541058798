import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ApiTest from "./ApiTest";
import { Logger } from "../../../api/loggers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  subTitle: {
    display: "block",
    color: theme.palette.text.disabled,
  },
}));

const LoggersList: React.FC<{
  loggers: Logger[];
}> = ({ loggers }) => {
  const classes = useStyles();

  return loggers.length ? (
    <div className={classes.root}>
      {loggers.map((logger) => (
        <Accordion key={`logger_${logger.id}`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`logger_${logger.id}_header`}
          >
            <Typography variant="h4">
              {logger.logger_id}
              <Typography
                component="span"
                variant="body2"
                className={classes.subTitle}
              >
                {logger.manufacturer}
              </Typography>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ApiTest loggerId={logger.id} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  ) : (
    <p>No associated loggers found</p>
  );
};

export default LoggersList;
