import { Typography, TextField, Box } from "@material-ui/core";
import { SMSAction } from "../../../../api/automation";
import { Spacer } from "../ActionCard";
import { useMemo } from "react";
import { RepeatDropdown } from "./RepeatDropdown";
import { Field } from "../../../../api/fields";

// permissive regex since being strict is complicated and requires a whole js library
const phoneRegex = /^\+?[\d\s()-]*$/;

export const SMSActionConfig: React.FC<{
  action: Partial<SMSAction>;
  setAction: (value: Partial<SMSAction>) => void;
  field: Field;
}> = ({ action, setAction, field }) => {
  const messageError = useMemo(() => {
    if (action.type !== "sms" || !action.message) {
      return;
    }
    if (action.message.length > 160) {
      return "Message must be 160 characters or less";
    }
  }, [action]);

  const phoneNumberError = useMemo(() => {
    if (action.type !== "sms" || !action.phone_number) {
      return;
    }
    if (!phoneRegex.test(action.phone_number)) {
      return "Invalid phone number";
    }
  }, [action]);

  return (
    <Box mt={1}>
      <Typography>
        Send a SMS message with content
        <Spacer>
          <TextField
            value={action.message}
            onChange={(e) =>
              setAction({
                ...action,
                message: e.target.value as string,
              })
            }
            error={!!messageError}
            helperText={messageError}
          ></TextField>
        </Spacer>
        to
        <Spacer>
          <TextField
            value={action.phone_number}
            onChange={(e) =>
              setAction({
                ...action,
                phone_number: e.target.value as string,
              })
            }
            error={!!phoneNumberError}
            helperText={phoneNumberError}
          />
        </Spacer>
      </Typography>
      <RepeatDropdown
        action={action}
        setAction={setAction}
        fieldFrequency={field.import_frequency}
      />
    </Box>
  );
};
