import api, { ServerResponse } from "./agritech";

export type Logger = { id: number; logger_id: string; manufacturer: string };
async function listLoggers(token: string) {
  const response = await api.get(`/loggers`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

async function getLogger(token: string, id: number) {
  const response = await api.get(`/loggers/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

async function saveLogger(token: string, values: object) {
  const response = await api.post(
    `/loggers`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function updateLogger(token: string, id: number, values: object) {
  const response = await api.patch(
    `/loggers/${id}`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function deleteLogger(token: string, id: number) {
  const response = await api.delete(`/loggers/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

async function connectionTest(token: string, id: number) {
  const response = await api.get<ServerResponse<Record<string, number>>>(
    `/loggers/api-test/${id}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export {
  listLoggers,
  getLogger,
  saveLogger,
  updateLogger,
  deleteLogger,
  connectionTest,
};
