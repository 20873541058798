import api, { ServerResponse } from "./agritech";

export type ClientField = {
  id: number;
  client_id?: number;
  name: string;
  farm?: string;
  field_type: "soil" | "substrate";
  crop_type?: string;
  timezone: string;
  auto_drain_facility?: string;
  ec_division_factor?: number;
  runoff_multiplication_factor?: number;
  chart_runoff_multiplication_factor?: number;
  conductivity_line?: number;
  import: 0 | 1;
  import_frequency: 5 | 15;
  last_import?: string;
  import_summary?: string; // JSON
  deleted_at?: string;
  loggers: any[];
  sensors: any[];
};

export type Client = {
  id: number | null;
  name?: string;
  module_access?: { module_id: number }[];
  users?: { id: number; name: string }[];
  fields?: ClientField[];
  directory?: string;
  api_config?: string; // JSON
  last_api_sync?: string;
};
type Frequency = [value: number, unit: "minutes" | "hours" | "days"];
export type APIConfig = {
  syncFrequency: Frequency;
  api: "costa";
};

export async function listClients(token: string) {
  const response = await api.get<ServerResponse<Client[]>>(`/clients`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export async function getClient(token: string, id: number) {
  const response = await api.get<ServerResponse<Client>>(`/clients/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export async function saveClient(token: string, client: Client) {
  const response = await api.post(
    `/clients`,
    { ...client },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

export async function updateClient(token: string, id: number, client: Client) {
  const response = await api.patch(
    `/clients/${id}`,
    { ...client },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

export async function deleteClient(token: string, id: number) {
  const response = await api.delete(`/clients/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}
