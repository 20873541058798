const squeezeLabels: Record<string, string> = {
  ves: "Very Easy Squeeze",
  es: "Easy Squeeze",
  ems: "Easy-Medium Squeeze",
  ms: "Medium Squeeze",
  mhs: "Medium-Hard Squeeze",
  hs: "Hard Squeeze",
  ti: "Tuber Initiation",
  hk: "Hooking",
  bb: "Bud Break",
  fl: "Flowering",
  gfs: "Green Fruit stage",
  crp: "Cropping",
  blb: "Bulbing",
  sb: "Set Back",
};
for (let dist = 10; dist <= 80; dist += 10) {
  squeezeLabels[`≤${dist}`] = `≤${dist}mm`;
}
for (let percent = 30; percent <= 70; percent += 2) {
  squeezeLabels[`${percent}%`] = `${percent}%`;
}
export default { options: squeezeLabels };
