import { MenuItem, Select, Box, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { Irrigator } from "../../../../api/irrigators";

import {
  IrrigatorCommand,
  listClientIrrigators,
  listIrrigatorCommands,
} from "../../../../api/irrigators";
import { Spacer } from "../ActionCard";
import { useAuthState } from "../../../../context/auth";
import { IrrigatorAction } from "../../../../api/automation";
import { RepeatDropdown } from "./RepeatDropdown";
import { Field } from "../../../../api/fields";

export const IrrigationAction: React.FC<{
  targetField: Field;
  action: Partial<IrrigatorAction>;
  setAction: (value: Partial<IrrigatorAction>) => void;
  isManual: boolean;
}> = ({
  targetField,
  action: dataObject,
  setAction: setDataObject,
  isManual,
}) => {
  const user = useAuthState();

  const [irrigators, setIrrigators] = useState<Irrigator[]>([]);
  const [irrigatorCommands, setIrrigatorCommands] = useState<
    IrrigatorCommand[]
  >([]);

  const fetchIrrigators = async () => {
    if (!user.token || !user.client.id) return;
    const irrigatorsResult = await listClientIrrigators(
      user.token,
      user.client.id
    );
    setIrrigators(irrigatorsResult.data);
  };

  const fetchIrrigatorCommands = async () => {
    if (!user.token) return;
    const irrigatorCommands = await listIrrigatorCommands(user.token);
    setIrrigatorCommands(irrigatorCommands.data);
  };

  useEffect(() => {
    fetchIrrigators();
    fetchIrrigatorCommands();
  }, [user.client.id]);

  const irrigatorCommandsList = useMemo(() => {
    if (dataObject?.irrigator_id) {
      const irrigator = irrigators.find(
        (i) => i.id === dataObject.irrigator_id
      );
      if (!irrigator) {
        return [];
      }
      return irrigatorCommands.filter((c) => {
        return c.irrigator_type_id === irrigator.irrigator_type_id;
      });
    }
    return [];
  }, [dataObject, irrigatorCommands]);

  return (
    <Box mt={1}>
      <Typography>
        For irrigator
        <Spacer>
          <Select
            label="irrigator"
            value={dataObject.irrigator_id ?? ""}
            error={!irrigators.some((i) => i.id === dataObject.irrigator_id)}
            onChange={(e) => {
              setDataObject({
                ...dataObject,
                irrigator_id: e.target.value as number,
              });
            }}
          >
            {irrigators?.map((i) => (
              <MenuItem value={i.id} key={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </Select>
        </Spacer>
        perform the following command
        <Spacer>
          <Select
            label="command"
            value={dataObject.command_id ?? ""}
            onChange={(e) => {
              const command = irrigatorCommands.find(
                (c) => c.id === e.target.value
              );
              if (!command) return;
              setDataObject({
                ...dataObject,
                command: command.command,
                command_id: command.id,
              });
            }}
          >
            {irrigatorCommandsList.map((c, i) => (
              <MenuItem value={c.id} key={i}>
                {c.name} ({c.command})
              </MenuItem>
            ))}
          </Select>
        </Spacer>
        {dataObject.irrigator_id && (
          <>
            via SMS message to
            <Spacer>
              {
                irrigators.find((i) => i.id === dataObject.irrigator_id)
                  ?.phone_number
              }
            </Spacer>
          </>
        )}
      </Typography>
      {!isManual && (
        <RepeatDropdown
          action={dataObject}
          setAction={setDataObject}
          fieldFrequency={targetField.import_frequency}
        />
      )}
    </Box>
  );
};
