import api from "./agritech";

export type Field = { id: number; timezone: string; import_frequency: 5 | 15 };
export async function getField(
  token: string,
  clientId: number,
  fieldId: number
) {
  const response = await api.get(`${clientId}/fields/${fieldId}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

type FieldData = { sensors: { multiplier: number }[] };

function formatValues(values: FieldData) {
  return {
    ...values,
    sensors:
      values.sensors && values.sensors.length
        ? values.sensors.map((sensor) => {
            const { multiplier, ...rest } = sensor;
            const config = multiplier ? JSON.stringify({ multiplier }) : null;
            return { ...rest, config };
          })
        : [],
  };
}

export async function saveField(
  token: string,
  clientId: number,
  values: FieldData
) {
  const response = await api.post(`${clientId}/fields`, formatValues(values), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export async function updateField(
  token: string,
  clientId: number,
  fieldId: number,
  values: FieldData
) {
  const response = await api.patch(
    `${clientId}/fields/${fieldId}`,
    formatValues(values),
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

export async function deleteField(
  token: string,
  clientId: number,
  fieldId: number
) {
  const response = await api.delete(`${clientId}/fields/${fieldId}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export async function deleteDataField(token: string, values: object) {
  const response = await api.post(
    `deleteFieldData`,
    { ...values },
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function importHistoricData(token: string, values: any) {
  const response = await api.post(
    `sensorDataImports`,
    { ...values },
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function refreshMildew(token: string, values: any) {
  const response = await api.post(
    `refreshMildewData`,
    { ...values },
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function listDeletedFields(token: string, clientId: number) {
  const response = await api.get(`${clientId}/deleted-fields`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export async function restoreField(
  token: string,
  clientId: number,
  fieldId: number
) {
  const response = await api.post(
    `${clientId}/fields/restore/${fieldId}`,
    {},
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}
