import type { PaletteColor } from "@material-ui/core/styles/createPalette";
import theme from "../../constants/theme";

export type VPDThreshold = {
  label: string;
  value: number;
  color: PaletteColor;
  fill: "start" | "end" | 1;
};

export const vpdThresholds = {
  low: {
    label: "Low",
    value: 5,
    color: theme.palette.info,
    fill: "start",
  },
  optimum: {
    label: "Optimum",
    value: 30,
    color: theme.palette.primary,
    fill: 1,
  },
  high: {
    label: "High",
    value: 40,
    color: theme.palette.secondary,
    fill: 1,
  },
  excess: {
    label: "Extreme",
    value: 40,
    // @ts-expect-error - danger not in the types for theme
    color: theme.palette.danger,
    fill: "end",
  },
} satisfies Record<string, VPDThreshold>;

export const getPercentageInThresholds = <K extends string >(
  data: ({[key in K]: number}&{ timestamp: number})[], thresholds: Record<string, VPDThreshold>, dataKey: K): [VPDThreshold, number][] => {
    const timeInThresholds = new Map<string, number>();
    const orderedThresholds = Object.values(thresholds).sort((a, b) => a.value - b.value);
    if (!data.length){
      return orderedThresholds.map((thresh) => [thresh, 0]);
    }
    const getThreshIdx = (val: number) => {
      for (const [idx, thresh] of orderedThresholds.entries()){
        if (thresh.fill === 'end'){
          return idx;
        }
        if (val < thresh.value){
          return idx;
        }
      }
      return orderedThresholds.length - 1;
    }
    if (data.length === 1){
      const point = data[0];
      const idx = getThreshIdx(point[dataKey]);
      const threshold = orderedThresholds[idx];
      return orderedThresholds.map((thresh) => {
        return [thresh, thresh === threshold ? 100 : 0];
      });
    }
    for (let i = 0; i < data.length - 1; i++){
      const startPoint = data[i];
      const endPoint = data[i + 1];
      const period = endPoint.timestamp - startPoint.timestamp;
      const stIdx = getThreshIdx(startPoint[dataKey]);
      const endIdx = getThreshIdx(endPoint[dataKey]);
      for (let t = stIdx; t <= endIdx; t++){
        const threshold = orderedThresholds[t];
        const startValue = t > stIdx ? orderedThresholds[t-1].value :startPoint[dataKey];
        let proportion = 1;
        const totalChange = Math.abs(endPoint[dataKey] - startPoint[dataKey]);
        if (t!== endIdx){
          const endValue = threshold.value;
          proportion = Math.abs(endValue - startValue) / totalChange;
        } else if (totalChange !== 0){
          proportion = Math.abs(endPoint[dataKey] - startValue) / totalChange;
        }
        const time = period * proportion;
        const current = timeInThresholds.get(threshold.label) ?? 0;
        timeInThresholds.set(threshold.label, current + time);
      }
    }
    const totalTime = data[data.length - 1].timestamp - data[0].timestamp;
    return orderedThresholds.map((thresh) => {
      const time = timeInThresholds.get(thresh.label) ?? 0;
      return [thresh, (time / totalTime) * 100];
    })

  }