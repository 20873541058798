import { Automation } from "../api/automation";

const first = <T, R>(
  arr: T[],
  fn: (item: T) => R | undefined
): R | undefined => {
  for (const item of arr) {
    const result = fn(item);
    if (result !== undefined) {
      return result;
    }
  }
  return undefined;
};
const getStartTime = (automation: Automation) => {
  return (
    first(automation.data.conditions, (condition) => {
      if (condition.type === "time") {
        return condition.start;
      }
    }) ?? "00:00" // Default start time if not found
  );
};
export const orderAutomations = (automations: Automation[]) => {
  return automations.sort((a, b) => {
    const fieldIdDiff = a.field_id - b.field_id;
    if (fieldIdDiff !== 0) {
      return fieldIdDiff;
    }
    if (a.data.disabled && !b.data.disabled) {
      return 1;
    }
    if (!a.data.disabled && b.data.disabled) {
      return -1;
    }
    const startTimeA = getStartTime(a);
    const startTimeB = getStartTime(b);
    if (startTimeA < startTimeB) {
      return -1;
    }
    if (startTimeA > startTimeB) {
      return 1;
    }
    // If all else is equal, keep the original order
    return 0;
  });
};
