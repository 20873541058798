import { useCallback, useEffect, useState, useMemo } from "react";
import { Hidden, useTheme } from "@material-ui/core/";
import Layout from "../layout/Layout";
import Loader from "../elements/Loader";
import FieldFilters from "../header/FieldFilters";
import Headline from "../elements/Headline";
import Copyright from "../elements/Copyright";
import FormButton from "../forms/FormButton";
import { Box, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  listAutomations,
  deleteAutomation,
  Automation,
} from "../../api/automation";
import { useAuthState, useAuthDispatch } from "../../context/auth";
import { AutomationCard } from "./automation/AutomationCard";
import { ManualIrrigationCard } from "./automation/ManualIrrigationCard";
import Modal from "../elements/Modal";
import Card from "../cards/Card";
import {
  useFieldsState,
  useFieldsDispatch,
  fetchFields,
} from "../../context/fields";
import { useFieldsFiltersState } from "../../context/field-filters";
import { filteredSortedFields } from "./Analytics/util";

import { MetricsProvider, useMetricsDispatch } from "../../context/metrics";
import { refresh } from "../../context/auth";
import { useSettingsDispatch } from "../../context/settings";

import {
  Irrigator,
  IrrigatorCommand,
  listClientIrrigators,
  listIrrigatorCommands,
} from "../../api/irrigators";
import { orderAutomations } from "../../helpers/automations";

const AutomationInner = () => {
  const [isLoading, setIsLoading] = useState(true);

  const user = useAuthState();
  const theme = useTheme();

  const { fields: allFields } = useFieldsState();

  const fieldsDispatch = useFieldsDispatch();
  const settingsDispatch = useSettingsDispatch();
  const authDispatch = useAuthDispatch();
  const metricsDispatch = useMetricsDispatch();

  const loadFields = useCallback(async () => {
    setIsLoading(true);
    try {
      await fetchFields(
        fieldsDispatch,
        user.client.id,
        user.token,
        user.client.directory
      );

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [
    fieldsDispatch,
    user.client.id,
    user.token,
    user.client.directory,
    settingsDispatch,
    user.settings,
  ]);

  //Load fields initially
  useEffect(() => {
    if (!allFields.length && user.token) {
      const { token, tokenExpiry } = user;
      refresh(authDispatch, { token, tokenExpiry });
      loadFields();
    }
  }, [authDispatch, user, loadFields, allFields, metricsDispatch]);

  const history = useHistory();

  const [automations, setAutomations] = useState<Automation[]>([]);

  const [toDelete, setToDelete] = useState<number>();
  useEffect(() => {
    if (!user.token || !user.client.id) return;
    listAutomations(user.token, user.client.id).then((automations) => {
      setAutomations(automations.data);

      setIsLoading(false);
    });
  }, []);

  const { farm, crop_type } = useFieldsFiltersState();
  const filteredFieldIds = useMemo(
    () =>
      new Set(
        filteredSortedFields(allFields, { crop_type, farm }).map(
          ({ id }: { id: number }) => id
        )
      ),
    [allFields, crop_type, farm]
  );
  const filteredAutomations = useMemo(() => {
    return orderAutomations(
      automations.filter((automation) => {
        const { field_id } = automation;
        return filteredFieldIds.has(field_id);
      })
    );
  }, [automations, filteredFieldIds]);

  const onDelete = useCallback(
    async (id) => {
      if (!user.token || !user.client.id) return;
      const result = await deleteAutomation(user.token, user.client.id, id);
      if (result.status === 1) {
        setAutomations(
          automations.filter((automation) => automation.id !== id)
        );
        setToDelete(undefined);
      }
    },
    [automations]
  );

  const [irrigators, setIrrigators] = useState<Irrigator[]>([]);
  const [irrigatorCommands, setIrrigatorCommands] = useState<
    IrrigatorCommand[]
  >([]);

  const fetchIrrigatorsAndCommands = useCallback(async () => {
    if (!user.token || !user.client.id) return;
    const irrigatorsResult = await listClientIrrigators(
      user.token,
      user.client.id
    );
    setIrrigators(irrigatorsResult.data);

    const irrigatorCommandsResult = await listIrrigatorCommands(user.token);
    setIrrigatorCommands(irrigatorCommandsResult.data);
  }, [user]);

  useEffect(() => {
    fetchIrrigatorsAndCommands();
  }, [fetchIrrigatorsAndCommands]);

  return (
    <Layout
      loading={!automations}
      header={
        <Hidden smDown>
          <FieldFilters
            fields={allFields}
            color={theme.palette.secondary.main}
          />
        </Hidden>
      }
    >
      <Loader
        active={isLoading}
        showChildren={false}
        message="Loading automations..."
      >
        <Box>
          <Headline headline="Automation" headlineVariant="h1">
            <Box>
              <FormButton
                text={"Add new Automation"}
                handleClick={() => history.push("/automation/config/new")}
                variant="contained"
                color="primary"
              />
            </Box>
          </Headline>

          <Hidden mdUp>
            <FieldFilters
              fields={allFields}
              color={theme.palette.primary.main}
            />
          </Hidden>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <ManualIrrigationCard fields={allFields} />
            </Grid>
            {filteredAutomations.map((automation) => (
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <AutomationCard
                  automation={automation}
                  irrigators={irrigators}
                  irrigatorCommands={irrigatorCommands}
                  onDelete={() => setToDelete(automation.id)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Modal open={toDelete !== undefined}>
          <Card>
            <Typography>
              Are you sure you want to delete this automation?
            </Typography>
            <Box display="flex" justifyContent="end" mt={4}>
              <FormButton
                text="Cancel"
                handleClick={() => setToDelete(undefined)}
                variant="contained"
              />
              <FormButton
                text="Delete"
                handleClick={() => onDelete(toDelete)}
                variant="contained"
                color="primary"
              />
            </Box>
          </Card>
        </Modal>
        <Copyright />
      </Loader>
    </Layout>
  );
};

export const AutomationPage = () => (
  <MetricsProvider>
    <AutomationInner />
  </MetricsProvider>
);
