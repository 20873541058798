import React from "react";
import { makeStyles, CssBaseline, Container } from "@material-ui/core/";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Notifier from "../elements/Notifier";
import { useSidebarDispatch, useSidebarState } from "../../context/sidebar";

const drawerWidth = 240;

const Layout: React.FC<{
  loading?: boolean;
  header?: React.ReactNode;
  sidebar?: React.ReactNode;
  footer?: React.ReactNode;
  disableGutters?: boolean;
}> = ({ children, header, sidebar, footer, disableGutters }) => {
  const { open, settings } = useSidebarState();
  const dispatch = useSidebarDispatch();
  const onToggleSidebar = () => {
    dispatch({ type: "setOpen", payload: !open });
    settings && dispatch({ type: "setSettingsOpen", payload: !settings });
  };

  const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
      position: "relative",
      paddingTop: `calc(64px + ${theme.spacing(3)}px)`,
      paddingBottom: theme.spacing(4),
      paddingLeft: open ? drawerWidth : 72,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
      transition: theme.transitions.create("padding", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <Notifier />
      <CssBaseline />
      <Header onToggleSidebar={onToggleSidebar} drawerWidth={drawerWidth}>
        {header}
      </Header>
      <Sidebar onToggleSidebar={onToggleSidebar} drawerWidth={drawerWidth}>
        {sidebar}
      </Sidebar>
      <main className={classes.container}>
        {children && (
          <Container maxWidth={false} disableGutters={disableGutters}>
            {children}
          </Container>
        )}
      </main>
      {footer ? <Footer drawerWidth={drawerWidth}>{footer}</Footer> : null}
    </div>
  );
};

export default Layout;
