import { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { submit } from "redux-form";
import FieldForm from "../../forms/admin/FieldForm";
import { useAuthState } from "../../../context/auth";
import { Client, listClients } from "../../../api/clients";
import { Metric, searchMetrics } from "../../../api/metrics";
import Card from "../../cards/Card";
import { handleRequestError, triggerToast } from "../../../helpers";
import { listLoggers, Logger } from "../../../api/loggers";
import LoggersList from "../../elements/admin/LoggersList";
import { Field, saveField, updateField } from "../../../api/fields";
import Loader from "../../elements/Loader";
import Headline from "../../elements/Headline";
import FormButton from "../../forms/FormButton";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Admin";

const ManageField = ({
  id,
  setIsLoading,
}: {
  id: number;
  setIsLoading(newValue: boolean): void;
}) => {
  const classes = useStyles();
  const user = useAuthState();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [loggers, setLoggers] = useState<Logger[]>([]);
  const [field, setField] = useState<Partial<Field>>();

  // Get meta data
  useEffect(() => {
    if (!user.token) return;
    setLoading(true);
    Promise.all([
      listClients(user.token),
      searchMetrics({ raw: 1, modules: [3, 4] }, user.token),
      listLoggers(user.token),
    ])
      .then(([tempclients, tempmetrics, temploggers]) => {
        setClients(tempclients.data);
        setMetrics(tempmetrics.data.sort((a, b) => (a.ref > b.ref ? 1 : -1)));
        setLoggers(temploggers.data);
        setLoading(false);
      })
      .catch((e) => {
        handleRequestError(e, "Failed fetching data: ");
      });
  }, [user.token]);

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  const clientsOptions = useMemo(
    () =>
      clients.reduce((obj, client) => {
        if (client.id != null) {
          obj[client.id] = client.name;
        }
        return obj;
      }, {} as Record<NonNullable<Client["id"]>, Client["name"]>),
    [clients]
  );
  // -------------

  // Get Field data (if ID provided)
  const getField = useCallback(
    async (id) => {
      const fields = clients
        .flatMap((client) => client.fields ?? [])
        .filter((clientField) => clientField.id === parseInt(id));
      const { sensors, ...restOfField } = fields.at(0) ?? {};
      const field =
        sensors && sensors.length
          ? {
              ...restOfField,
              sensors: sensors.map((sensor) => {
                const { config, ...restOfSensor } = sensor;
                if (config) {
                  return { ...restOfSensor, ...JSON.parse(config) };
                } else {
                  return sensor;
                }
              }),
            }
          : fields[0];
      setField(field);
    },
    [clients]
  );

  useEffect(() => {
    id && getField(id);
  }, [getField, id]);
  // -------------

  // Populate Form & related loggers
  const formValues = useSelector(
    ({ form: { FieldForm } }: any) => FieldForm && FieldForm.values
  );
  const { sensors }: { sensors: { logger_id: number }[] } = formValues || [];
  const fieldLoggers = useMemo(() => {
    if (!loggers || !sensors) return [];
    const loggerIds = sensors.map((item) => item.logger_id).filter((id) => id);
    return loggers.filter((item) => loggerIds.includes(item.id));
  }, [loggers, sensors]);
  // -------------

  const handleSubmit = async (formValues: any) => {
    if (!user.token) return;
    setWaiting(true);
    try {
      const {
        id,
        client_id,
        name,
        farm,
        field_type,
        crop_type,
        auto_drain_facility,
        runoff_multiplication_factor,
        chart_runoff_multiplication_factor,
        ec_division_factor,
        conductivity_line,
        timezone,
        sensors,
        import_frequency,
      } = formValues;

      const values = {
        name,
        farm,
        field_type,
        crop_type,
        auto_drain_facility,
        runoff_multiplication_factor: parseFloat(runoff_multiplication_factor),
        chart_runoff_multiplication_factor: parseFloat(
          chart_runoff_multiplication_factor
        ),
        ec_division_factor: parseFloat(ec_division_factor),
        conductivity_line,
        timezone,
        sensors,
        import: formValues.import || false,
        import_frequency,
      };
      const response = id
        ? await updateField(user.token, client_id, id, values)
        : await saveField(user.token, client_id, values);
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      history.push("/admin");
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to ${id ? "update" : "save"} Field: `);
    }
  };

  return (
    <Loader active={loading} showChildren={false} message={`Loading Field...`}>
      <Headline headline={`Edit Field`} headlineVariant="h1" divider={true}>
        <FormButton
          text={id ? "Update" : "Save"}
          disabled={!valid || waiting}
          handleClick={() => dispatch(submit("FieldForm"))}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText={id ? "Updating" : "Saving"}
        />
      </Headline>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12} lg={7}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.subTitle}
              >
                DETAILS:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <FieldForm
                  // @ts-expect-error loggers prop not currently defined in js component
                  loggers={loggers}
                  metrics={metrics}
                  initialValues={field}
                  clients={clientsOptions}
                  isValid={(status: boolean) => setValid(status)}
                  isWaiting={(status: boolean) => setWaiting(status)}
                  onSubmit={handleSubmit}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.subTitle}
              >
                LOGGERS:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LoggersList loggers={fieldLoggers} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ManageField;
