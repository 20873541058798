import { Typography, Select, MenuItem } from "@material-ui/core";
import { IrrigatorAction, RepeatConfig } from "../../../../api/automation";
import { Spacer } from "../ActionCard";

const baseOptions: { label: string; value: RepeatConfig }[] = [
  { label: "Every 30 minutes", value: "30min" },
  { label: "Every 45 minutes", value: "45min" },
  { label: "Every hour", value: "hourly" },
  { label: "Every 90 minutes", value: "90min" },
  { label: "Every 2 hours", value: "2hr" },
  { label: "Never", value: "never" },
];

const fastOptions: typeof baseOptions = [
  { label: "Every 5 minutes", value: "always" },
  { label: "Every 10 minutes", value: "10min" },
  { label: "Every 15 minutes", value: "15min" },
  { label: "Every 20 minutes", value: "20min" },
  ...baseOptions,
];

const slowOptions: typeof baseOptions = [
  { label: "Every 15 minutes", value: "always" },
  ...baseOptions,
];

type JustRepeat = Pick<IrrigatorAction, "repeat">;

export const RepeatDropdown: React.FC<{
  action: JustRepeat;
  setAction: (value: JustRepeat) => void;
  fieldFrequency: 5 | 15;
}> = ({ action, setAction, fieldFrequency }) => {
  console.log("fieldFrequency", fieldFrequency);
  const options = fieldFrequency === 5 ? fastOptions : slowOptions;
  return (
    <Typography>
      Repeat
      <Spacer>
        <Select
          label="repeat"
          value={action.repeat ?? "hourly"}
          onChange={(e) => {
            setAction({
              ...action,
              repeat: e.target.value as RepeatConfig,
            });
          }}
        >
          {options.map((c, i) => (
            <MenuItem value={c.value} key={i}>
              {c.label}
            </MenuItem>
          ))}
        </Select>
      </Spacer>
    </Typography>
  );
};
