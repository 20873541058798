import api, { ServerResponse } from "./agritech";

async function listMetrics(token: string) {
  const response = await api.get<ServerResponse<Metric[]>>(`/metrics`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export type Metric = {
  ref: number;
};

async function searchMetrics(
  params: { raw: 0 | 1; modules: number[] },
  token: String
) {
  const response = await api.post<ServerResponse<Metric[]>>(
    `/metrics`,
    { ...params },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export { listMetrics, searchMetrics };
